// some common things used on the cloud portal page

// Clicks 'fileInput' element so a file selection dialog is opened
const selectFiles = function()
{
	let fileInput = document.getElementById('fileInput');
	fileInput.click();
};

const getel  = function(id)
{
	return document.getElementById(id);
};

const crel = function(type)
{
	return document.createElement(type);
};

// Redirecto to the manage group page
const goToGroupPage = function()
{
	window.location = "index.html";
	window.body.load();
};

const notificationMessage = function(parentElement, title, text, extraStyle)
{
	let div = crel('div');
	div.classList.add('notification');
	div.classList.add(extraStyle);
	parentElement.appendChild(div);
	let divInner = crel('div');
	divInner.classList.add('flex-1');
	div.appendChild(divInner);
	let h2 = crel('h2');
	h2.classList.add('notification__title');
	h2.innerHTML = title;
	divInner.appendChild(h2);
	let p = crel('p');
	p.classList.add('notification__body');
	p.innerHTML = text;
	divInner.appendChild(p);
	let button = crel('button');
	button.innerHTML = "Dismiss";
	button.onclick = function () { div.remove(); };
	div.appendChild(button);
};



// Creates error notification inside parentElement
const errorNotification = function(parentElement, title, text)
{
	notificationMessage(parentElement, title, text, 'notification--error');
};

// Creates warning notification inside parentElement
const warningNotification = function(parentElement, title, text)
{
	notificationMessage(parentElement, title, text, 'notification--warning');
};

// Shows error message inside element with the id 'errorArea'
const showErrorMessage = function(message)
{
	let errorArea = document.getElementById('errorArea');
	errorNotification(errorArea, 'Error', message);
};

// Shows warning message inside element with the id 'errorArea'
const showWarningMessage = function(message)
{
	let errorArea = document.getElementById('errorArea');
	warningNotification(errorArea, 'Warning', message);
};


// checks if HTML web storage is supported in the browser
const isStorageSupported = function() {
	if (typeof(Storage) !== "undefined") {
		console.log("Storage is supported.");
		return true;
	}
	else {
		console.warn("Storage is not supported.");
		return false;
	}
};

// Get the (encryption key)/(group id) from the URL bar in the browser
// Returns it as a base64 encoded string
const getEncryptionKeyFromUrl = function() {
	try {
		return Util.getUrlPar(window.location, 'groupid');
	}
	catch (error) {
		console.error("Unexpected error while trying to get group ID from URL!", error);
		return null;
	}
};

// Try Retrieving encryption key from URL or storage
// If it can't be retrieved, null is returned
const retrieveEncryptionKey = function() {

	// First try getting it from the URL
	let base64 = getEncryptionKeyFromUrl();

	// If we can't get it from the URL then try getting it from local storage
	if (base64 === null) {
		try {
			if (isStorageSupported()) {
				base64 = localStorage.getItem(Constants.EncryptionKeyItemName);
			}
		}
		catch (error) {
			console.error("Unexpected error while trying to retrieve encryption key from local storage: ", error);
		}
	}

	if (base64 === null || base64 === '' || base64 === 'null') return null;

	let encrKeyBytes = null;
	try {
		encrKeyBytes = CryptoUtil.base64ToBytes(base64); // Convert it to bytes
	} catch (error) {
		console.error("Unexpected error while trying to convert base64 encoded group id: ", error);
	}

	return encrKeyBytes === '' ? null : encrKeyBytes;
};

// Try storing encryption key in browser storage
// for later use
const storeEncryptionKey = function(encrKey)
{
	if (isStorageSupported())
	{
		var base64 = CryptoUtil.bytesToBase64(encrKey);
		localStorage.setItem(Constants.EncryptionKeyItemName, base64);
	}
};

const isLinked = function()
{
	var key = retrieveEncryptionKey();
	if (key != null)
	{
		return true;
	}
	return false;
};

const makeElementHidden = function(el)
{
	el.classList.add('hidden');
};

const makeElementVisible = function(el)
{
	el.classList.remove('hidden');
};

// If a group id is in the URL of the page, then add that to the tab links
const addGroupIdToTabLinksIfNeeded = function()
{
	let groupId = getEncryptionKeyFromUrl();
	if (groupId != null)
	{
		let links = [document.getElementById('indexLink'),
			document.getElementById('uploadsLink'),
			document.getElementById('downloadsLink')];
		let i = 0;
		for (i = 0; i < links.length; i++)
		{
			links[i].href = Util.addOrUpdateUrlParameter(links[i].href, 'groupid', groupId);
		}
	}
};

// Remove entry from index file in cloud
// entryHash : hash of the entry
// onError : method to call if there is an error
const removeEntryFromIndex = async function (entryHash, onError)
{
	let result = {};
	result.success = false;

	if (!isLinked())
	{
		goToGroupPage();
		return result;
	}

	try
	{
		let encrkey = retrieveEncryptionKey();
		console.log('Removing entry from index file.');
		result = await Cloud.removeFromIndex(entryHash, encrkey);
	}
	catch(err)
	{
		console.error('Error in removeEntryFromIndex:', err);
	}

	if (!result.success)
	{
		if (onError != null)
		{
			onError('A failure occured while trying to remove entry!');
		}
	}

	return result;
};

// For downloading a cloud resource in a browser
// Will download object with name objectName
// Opens a save file dialog
// objectName : name/hash of object in cloud
// nrOfParts : number of parts for multipart download
// filename : filename to show
// onError : Method to call when there is an error
const downloadCloudObject = async(objectName, nrOfParts, totalNrOfBytes, filename, onError, onWarning) =>
{
	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	try
	{
		if (totalNrOfBytes > 200000000)
		{
			if (onWarning != null)
			{
				onWarning('Files are larger than 200MB, download might fail.');
			}
		}

		var encrkey = retrieveEncryptionKey();
		console.log('Getting object ' + objectName + ' from cloud.');
		const getRes = await Cloud.getCloudFile(objectName, encrkey, nrOfParts);

		if (getRes.success)
		{
			console.log('Got file from cloud.');
			var filenameWithoutExt = Util.getFilenameWithoutExtension(filename);
			Cloud.saveBytesAsFile(getRes.decryptedBytes, "application/zip", "Arkio_" + filenameWithoutExt + ".zip");
		}
		else
		{
			if (onError != null)
			{
				onError('Failed to download file from cloud!');
			}
			
		}
	}
	catch(err)
	{
		console.error('Error in downloadCloudObject', err);
		if (onError != null)
		{
			onError('A failure occured while trying to download a file from the cloud!');
		}
	}
};

// Creates a "button" cell in a html table
// Used for creating download and remove buttons
// text : The text on the button
// iconHTML : html code defining how a icon for mobile looks
// action : Function to call when user clicks on the button
const createButtonTD = function(text, iconHTML, action)
{
	let td2 = document.createElement('td');
	let td2div = document.createElement('div');
	td2div.setAttribute('class', 'flex justify-end');
	
	let td2a = document.createElement('a');
	td2a.setAttribute('href', '#');
	td2a.setAttribute('class', 'btn btn-small hidden md:inline');
	td2a.onclick = action;
	let td2span = document.createElement('span');
	td2span.innerHTML = text;
	td2a.appendChild(td2span);
	td2div.appendChild(td2a);

	if (iconHTML != null)
	{
		let td2a2 = crel('a');
		td2a2.setAttribute('href', '#');
		td2a2.setAttribute('class', 'inline md:hidden w-4');
		td2a2.onclick = action;
		td2a2.innerHTML = iconHTML;

		td2div.appendChild(td2a2);
	}

	/*
	let td2icon = crel('icon');
	td2icon.setAttribute('class', 'w-4');
	td2icon.setAttribute('src', 'download-circle.svg');
	td2a2.appendChild(td2icon);
	*/
	
	td2.appendChild(td2div);

	return td2;
};

// Fill a table with cloud entries
// tableBody: table body html element
// entries: Cloud entries to show in the table
// enableDownload: If true, a download button will be added for each row
// enableRemove : If true, a remove button will be added for each row
// showFileCount: If true, then number of extra files will be shown in the filename field
// onEntryRemoved : Method to call after an entry has successfully been removed
const fillTableWithEntries = function(tableBody, entries, enableDownload, enableRemove, showFileCount, onErr, onWarn, onEntryRemoved)
{
	// For ensuring that the value of hash
	// is fixed in downloadCloudObject
	const createDownloadFunc = function(hash, nrOfParts, totalNrOfBytes, filename, onError, onWarning)
	{
		return function() { downloadCloudObject(hash, nrOfParts, totalNrOfBytes, filename, onError, onWarning); };
	};
	const createRemoveFunc = function(hash, onError)
	{
		return async function() {
			if (confirm("Remove this uploaded file?") === true) {
				let res = await removeEntryFromIndex(hash, onError);
				if (res.success)
				{
					if (onEntryRemoved != null) {
						onEntryRemoved(res.indexFile);
					}
				}
			}
		};
	};

	tableBody.innerHTML = '';
	let n = entries.length;
	for (let i = 0; i < n; i++)
	{
		let entry = entries[i].entryValue;
		let entryHash = entries[i].entryHash;
		let filename = '';
		let fileCount = 0;
		let created = entry.Created;
		let nrOfParts = 1;
		let totalNrOfBytes = 0;

		if (entry.File != null)
		{
			nrOfParts = entry.File.NrOfParts;
			totalNrOfBytes = entry.File.TotalNrOfBytes;
			filename = entry.File.Filename;
			fileCount = entry.File.FileCount;
		}
		if (entry.Stream != null)
		{
			filename = entry.Stream.Filename;
		}

		// the table row
		let tr = document.createElement('tr');

		// filename column
		let filenamePlusExtra = Util.getFilenameFromPath(filename);

		// Try getting the width of the section
		let contentWidth = 0;
		try
		{
			let contentSection = document.getElementById('contentSection');
			let positionInfo = contentSection.getBoundingClientRect();
			contentWidth = positionInfo.width;
		}
		catch(error)
		{
			console.error('Error when trying to get width of element', error);
		}

		if (contentWidth === 0)
		{
			// In case we can't get the width of the element then we let
			// the width of the page control how many characters are shown
			// So this will work well different screen sizes
			contentWidth = document.documentElement.scrollWidth;
		}

		let maxLen = Math.floor(contentWidth/20);

		if (showFileCount && fileCount > 1)
		{
			filenamePlusExtra = filenamePlusExtra + " and " + (fileCount - 1) + " other file(s)";
		}
		if (filenamePlusExtra.length > maxLen)
		{
			filenamePlusExtra = filenamePlusExtra.substring(0, maxLen-3) + "...";
		}
		let td0 = document.createElement('td');
		let td0div = document.createElement('div');
		td0div.setAttribute('class', 'flex items-center gap-2 max-w-2xs sm:max-w-none');
		let td0span = document.createElement('span');
		td0span.setAttribute('class', 'truncate flex-1');
		td0span.innerHTML = filenamePlusExtra;
		//td0div.appendChild(td0icon);
		td0div.appendChild(td0span);
		td0.appendChild(td0div);

		// Reformatting the date string a bit
		let month = created[0] + created[1];
		let day = created[3] + created[4];
		let year = created[6] + created[7] + created[8] + created[9];
		let createdDateForUI = year + '/' + month + '/' + day + created.substring(10);
		
		// date column
		let td1 = document.createElement('td');
		td1.innerHTML = createdDateForUI;

		tr.appendChild(td0);
		tr.appendChild(td1);

		if (enableDownload)
		{
			// download column
			let downloadFunction = createDownloadFunc(entryHash, nrOfParts, totalNrOfBytes, filename, onErr, onWarn);

			// Get the download icon
			// Doing it like this because I could not figure out
			// how to reference the icon from a path
			// when making the element dynamically
			// when using parcel
			// maybe there is a way to do that, but this works
			let downloadIcon = document.getElementById("DownloadCircleIcon");
			let iconHTML = downloadIcon.innerHTML;

			let td2 = createButtonTD('Download', iconHTML, downloadFunction);

			tr.appendChild(td2);
		}

		if (enableRemove)
		{
			let removeFunction = createRemoveFunc(entryHash, onErr);

			// Getting icon html in similar way as with the download icon
			// see comment there
			let trashIcon = document.getElementById("TrashIcon");
			let iconHTML = trashIcon.innerHTML;
			let td3 = createButtonTD('Remove', iconHTML, removeFunction);
			tr.appendChild(td3);
		}

		// Add row to body of table
		tableBody.appendChild(tr);
	}
};

// get index file if possible
// returns index file object if it was possible to get it
// else returns null
// need to be connected to group
// if not connected, this will redirect to the group page
const getIndexFile = async function(onError)
{
	if (!isLinked())
	{
		goToGroupPage();
	}
	else
	{
		let encrkey = retrieveEncryptionKey();
		let indexRes = await Cloud.getIndexFromCloud(encrkey);

		if(!indexRes.success)
		{
			if (indexRes.httpStatusCode === 404)
			{
				// This should not happen since the index file should
				// always exist after a group has been created.
				console.error('No index file in cloud!');
			}
			else
			{
				console.error('Failed to download index file from cloud!');
				onError("Failed to download records from cloud!");
			}
		}
		else
		{
			let indexFile = indexRes.indexFile;
			return indexFile;
		}
	}
	return null;
};

// Updates uploading progress/complete messages
// to show either a progressing or upload complete message
// error : set this to true if an error has happened
// If error is true then an upload complete message won't
// be shown although an progressing message could be
// shown if there are still files being processed.
// error: If true then the upload complete message
// will not be shown, even if the nr of files being sent
// has reached 0
const updateUploadingMessages = function(error, totalNrOfFilesBeingSent, nrOfOngoingUploadTasks)
{
	let uploadComplete = document.getElementById('uploadCompleteNotificationDiv');
	let uploadProgress = document.getElementById('uploadProgressNotificationDiv');

	if (nrOfOngoingUploadTasks === 0)
	{
		makeElementHidden(uploadProgress);
		if (!error)
		{
			makeElementVisible(uploadComplete);
		}
	}
	else
	{
		makeElementHidden(uploadComplete);
		let uploadProgressBody = document.getElementById('uploadProgressNotificationBody');
		if (totalNrOfFilesBeingSent > 0)
		{
			uploadProgressBody.innerHTML = 'Uploading ' + totalNrOfFilesBeingSent + ' files';
		}
		else
		{
			uploadProgressBody.innerHTML = 'Uploading';
		}
		makeElementVisible(uploadProgress);
	}
};

// Opens save dialog for user to save file to disk
// buffer : A buffer with data to save
// filename : Name of the file to save
const saveBufferToDisk = function(buffer, filename)
{
	var blob = new Blob([buffer], { type: 'application/octet-stream' });
	var link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = filename;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
